.t-loginPg {
  @include max-screen-mobile;

  &_form {
    &_field {
      &:not(:first-child) {
        margin-top: rem(24);
      }
    }

    &_submit {
      max-width: 240px;
      margin: rem(30) auto 0;
    }
  }
}