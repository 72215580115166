.a-checkbox {
  $root: &;

  &_main {
    cursor: pointer;
    position: relative;
    @include adjust-flex(flex-start, center);

    input {
      position: absolute;
      visibility: hidden;
      width: 0;

      &:checked~.a-checkbox_square {
        &::before {
          border-bottom: 4px solid $permanent-geranium-lake;
          border-right: 4px solid $permanent-geranium-lake;
          content: '';
          height: rem(15);
          left: 6px;
          position: absolute;
          top: 0;
          transform: rotate(45deg);
          width: rem(8);

          @include mobile-down {
            width: rem(6);
            height: rem(10);
            border-width: 3px;
            left: 5px;
          }
        }
      }
    }

    &_label {
      cursor: pointer;
      flex-grow: 1;
      padding: rem(6) rem(24) rem(6) rem(36);
      position: relative;
      font-style: italic;
      color: $arsenic;

      @include mobile-down {
        font-size: rem(14);
        padding-right: rem(10);
        padding-left: rem(32);
      }

      p {
        line-height: rem(26);
        padding-left: rem(14);
        position: relative;
        z-index: 1;

      }
    }
  }

  &_square {
    border: 2px solid $spanish-gray;
    box-sizing: border-box;
    height: rem(28);
    left: 0;
    position: absolute;
    width: rem(28);
    top: rem(9);

    @include mobile-down {
      height: rem(22);
      width: rem(22);
    }

    &_label {
      box-sizing: border-box;
      height: rem(28);
      left: 0;
      position: absolute;
      width: rem(28);
      top: rem(9);
      z-index: 2;
      cursor: pointer;
    }
  }

  &_error {
    color: $red;
    margin-top: rem(4);
    @include font-base(12, 18);
  }
}