.o-container {
  padding-left: rem(15);
  padding-right: rem(15);

  @include break-min(1366px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1366px;
    padding-left: rem(110);
    padding-right: rem(110);
  }

  &-noPaddingRight {
    padding-right: 0;
  }

  &-noPaddingLeft {
    padding-left: 0;
  }

  &-fullscreen {
    padding-left: 0;
    padding-right: 0;
  }

  &-pd-lg {
    @include break-min(1366px) {
      padding-left: rem(184);
      padding-right: rem(184);
    }
  }

  &-pd-sm {
    @include break-min(1366px) {
      padding-left: rem(56);
      padding-right: rem(56);
    }
  }
}
