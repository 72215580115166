// fonts
$font-path: '~assets/fonts/';
// transition
$transition: 0.25s;
$transition-long: 0.4s;

// z-index
$z-header: 100;
$z-modal: 101;
$z-2: 2;

$max-width-screen: 450px;
