html,
body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
  &:focus {
    outline: none;
  }
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

a {
  text-decoration: none;
}

[role='button'],
button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

input {
  word-break: normal;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

.hidden-animation {
  visibility: hidden;
}

.overflow-hidden {
  height: 100vh;
}
