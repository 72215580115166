.m-otpInput {
  position: relative;

  @include mobile-down {
    margin-bottom: rem(20);
  }

  &_wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: rem(20);
  }

  &_error {
    bottom: rem(-22);
    color: $red;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
  }

  .a-input {
    margin-left: rem(8);
    margin-right: rem(8);

    @include tablet-down {
      margin-left: rem(7);
      margin-right: rem(7);
    }
  }
}
